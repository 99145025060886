<template>
  <linearGradient
    id="handle-gradient"
    :x1="handleX"
    y1="0"
    :x2="handleX2"
    y2="0"
    gradientUnits="userSpaceOnUse">
    <stop offset="5%" stop-color="#7c7e80"/>
    <stop offset="20%" stop-color="#b5b5b5"/>
    <stop offset="40%" stop-color="#f8f8f9"/>
    <stop offset="66%" stop-color="#bfbfc1"/>
    <stop offset="95%" stop-color="#656567"/>
  </linearGradient>
</template>

<script>
export default {
  props: [
    'handleX',
    'doorLeftWidth',
  ],
  computed: {
    handleX2() {
      return this.handleX + 5.6
    },
  }
}
</script>
